import React from "react";

function Home() {
  return (
    <div className="home">
      <img className="wallImage" src="banner.jpg" alt=""/>
    </div>
  );
}

export default Home;