import React from "react";
import config from '../data/config';

function Header() {
  return (
    <div className="header">
      <div className="logodiv"><img className="logo192" src="logo192.png" alt="jerky676" /></div>
      <div className="about"><p>{config.about}</p></div>
    </div>
  );
}

export default Header;