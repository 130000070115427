import { FaGithub } from 'react-icons/fa/';
import { FaInstagram } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

import config from './config';

const data = [
  {
    link: config.github,
    label: 'Github',
    icon: <FaGithub/>,
    color: "black",
    size:"6em",
  },
  {
    link: config.instagram,
    icon: <FaInstagram/>,
    color: "black",
    size:"6em",
  },
  {
    link: config.twitter,
    label: 'Twitter',
    icon: <FaTwitter/>,
    color: "black",
    size:"6em",
  },
  {
    link: `mailto:${config.email}`,
    label: 'Email',
    icon: <FaEnvelope/>,
    border: false,
    color: "black",
    size:"6em",
  },
];

export default data;